/* Css reset */
* {
  margin: 0;
  padding: 0;
  border: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-size: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

/* -- Css reset */

/* General Css rules */
body {
  background-color: #020c1b;
  font-family: "Roboto Mono", monospace;
  color: #8892b0;
  font-weight: 400;
}

section.blur {
  filter: blur(5px) brightness(0.7);
  pointer-events: none;
  user-select: none;
}

ol,
ul {
  list-style: none;
}

#root > section {
  margin: 0 30px;
  max-width: 1000px;
}

@media (min-width: 800px) {
  #root > section {
    margin: 0 150px;
  }
}

@media (min-width: 1500px) {
  #root > section {
    margin: 0 auto;
  }
}

#root > section div > .main {
  padding: 0 10px;
}
/* -- General Css rules */

/* HEADER */
/* Breack point 1000px; */

header {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: rgba(10, 25, 47, 1);
  box-shadow: 0 10px 30px -10px rgba(2, 12, 27, 0.7);
  font-size: 14px;
}

header nav {
  padding: 10px 40px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header nav .logo {
  display: inline-block;
  color: #64ffda;
  text-decoration: none;
  font-size: 30px;
  border: 1px solid #64ffda;
  border-radius: 20%;
  padding: 5px 15px;
  margin-right: 30px;
}

header nav .nav {
  display: none;
}

header nav .nav a {
  color: #8892b0;
  text-decoration: none;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

header nav .nav .resume {
  color: #64ffda;
  background-color: transparent;
  border: 1px solid #64ffda;
  padding: 10px 15px;
  border-radius: 5px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

header nav .nav .resume:hover {
  background-color: rgba(100, 255, 218, 0.1);
}

@media (min-width: 700px) {
  header nav .nav {
    display: inline-block;
  }
}

header nav .nav a:hover {
  cursor: pointer;
  color: #64ffda;
}

header nav .nav .nav-list {
  display: inline-block;
  padding-right: 10px;
}

header nav .nav .nav-list a {
  display: inline-block;
}

header nav .nav .nav-list li {
  display: inline-block;
  padding: 5px 10px;
  color: #8892b0;
}

header nav .nav .nav-list li:hover {
  cursor: pointer;
  color: #64ffda;
}

/* -- HEADER */

/* Hamburger-button Section */
.hamburger-content {
  display: inline-block;
  position: fixed;
  z-index: 25;
  top: 10px;
}

@media (min-width: 700px) {
  .hamburger-content {
    display: none;
  }
}

.hamburger-button {
  list-style: none;
  margin-top: 5px;
  position: fixed;
  right: 30px;
  width: 50px;
  z-index: 30;
}

.hamburger-button li {
  height: 4px;
  margin: 8px auto;
  background-color: #64ffda;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.hamburger-button li:nth-child(1) {
  width: 42px;
}
.hamburger-button li:nth-child(2) {
  width: 32px;
}
.hamburger-button li:nth-child(3) {
  width: 20px;
}

.hamburger-button.open li:nth-child(1) {
  -webkit-transform: rotate(45deg) translate(8px, 12px);
  -ms-transform: rotate(45deg) translate(8px, 12px);
  transform: rotate(45deg) translate(8px, 12px);
}

.hamburger-button.open li:nth-child(2) {
  -webkit-transform: rotate(-45deg) translate(-12px, -1.5px);
  -ms-transform: rotate(-45deg) translate(-12px, -1.5px);
  transform: rotate(-45deg) translate(-10px, -1.5px);
}

.hamburger-button.open li:nth-child(3) {
  -webkit-transform: rotate(-45deg) translate(16px, -10px);
  -ms-transform: rotate(-45deg) translate(16px, -10px);
  transform: rotate(-45deg) translate(16px, -10px);
}
/* --Hamburger-button Section */

/* Experience Section */

.experience_title {
  padding-left: 15px;
  margin-bottom: 30px;
  font-size: 40px;
  color: #ccd6f6;
}

.MuiTabs-vertical {
  min-width: 150px;
}

.Mui-selected {
  color: #64ffda;
}

.MuiBox-root {
  padding: 13px 24px 24px 24px !important;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: #64ffda !important;
}

.MuiTabs-indicator.jss4 {
  background-color: #64ffda !important;
}

@media (min-width: 700px) {
  .jss92 {
    height: auto;
    display: flex;
    flex-grow: 1;
  }
}

.company {
  color: #64ffda !important;
}

.company a {
  color: #64ffda !important;
  text-decoration: none;
}

.company a:hover {
  text-decoration: underline;
}

.timeline {
  font-size: 13px;
}

.company-jobs {
  padding: 20px 20px;
  position: relative;
}

.company-jobs li {
  margin-bottom: 10px;
}

.company-jobs li:before {
  content: "▹";
  left: 0px;
  color: #64ffda;
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  left: 0px;
}
/* --Experience Section */
/* Aside */
aside {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: min(70vw, 500px);
  height: 100vh;
  z-index: 11;
  outline: 0px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transform: translateX(100vw);
  visibility: visible;
}

aside.open {
  transform: translatex(0vw);
}

aside .aside-content {
  padding: 50px;
  height: 100%;
  position: relative;
  background-color: rgb(23, 42, 69);
}

aside .aside-content ol {
  width: 100%;
  padding-top: 20vh;
}

aside .aside-content ol li {
  color: #ccd6f6;
  font-size: 25px;
  font-family: Roboto;
  text-align: center;
  margin-bottom: 60px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

aside .aside-content ol li:hover {
  cursor: pointer;
  color: #64ffda;
}

aside .aside-content ol li .resume {
  color: #64ffda;
  background-color: transparent;
  border: 1px solid #64ffda;
  padding: 10px 25px;
  border-radius: 5px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-decoration: none;
}

aside .aside-content ol li .resume:hover {
  background-color: rgba(100, 255, 218, 0.1);
}
/* --Aside */

/* Intro Section */

.main.intro {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
}

.main.intro .intro_section h1 {
  color: red;
  font-weight: 400;
  color: #64ffda;
  margin-bottom: 15px;
}

.main.intro .intro_section h2 {
  font-size: 35px;
  color: #ccd6f6;
  margin-bottom: 10px;
}

.main.intro .intro_section h3 {
  color: #8892b0;
  font-size: 35px;
  font-weight: 600;
  line-height: 0.95;
  margin-bottom: 30px;
}

.main.intro .intro_content {
  margin-bottom: 45px;
  max-width: 500px;
}

.main.intro .intro_cta {
  margin-bottom: 45px;
  background-color: transparent;
  border: 1px solid #64ffda;
  padding: 20px 25px;
  border-radius: 5px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 15px;
}

.main.intro .intro_cta a {
  color: #64ffda;
  text-decoration: none;
}

.main.intro .intro_cta:hover {
  background-color: rgba(100, 255, 218, 0.1);
  cursor: pointer;
}

@media (min-width: 800px) {
  .main.intro .intro_section h2 {
    font-size: 45px;
  }
}

@media (min-width: 1400px) {
  .main.intro .intro_section h2 {
    font-size: 60px;
  }
  .main.intro .intro_section h3 {
    font-size: 50px;
  }
}

/* --Intro Section */

/* About me Section */
.main.about_me {
  display: flex;
  flex-wrap: wrap;
}

.main.about_me .left {
  flex-basis: 100%;
}

.main.about_me .right {
  flex-basis: 100%;
}

@media (min-width: 1000px) {
  .main.about_me .left {
    flex-basis: 50%;
  }
  .main.about_me .right {
    flex-basis: 50%;
  }
}

.main.about_me .about_title {
  font-size: 40px;
  color: #ccd6f6;
  margin-bottom: 30px;
}

.main.about_me .about_content {
  margin-bottom: 40px;
}

.main.about_me .about_content p {
  margin-bottom: 15px;
}

.main.about_me .about_content ul {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
}

.main.about_me .about_content ul li {
  flex-basis: 50%;
  margin-bottom: 10px;
}

.main.about_me .about_content ul li:before {
  content: "▹";
  left: 0px;
  color: #64ffda;
  font-size: 20px;
  line-height: 12px;
  padding-right: 5px;
}

.main.about_me .about_img {
  margin: 30px auto 50px;
  width: auto;
  background-color: #64ffda;
  position: relative;
  max-width: 250px;
  border-radius: 15px;
}

.main.about_me .about_img img {
  max-width: 100%;
  width: 250px;
  vertical-align: middle;
  filter: grayscale(100%);
  mix-blend-mode: multiply;
  transition: opacity 500ms ease 0s;
  border-radius: 15px;
}

.main.about_me .about_img:hover img {
  filter: none;
  mix-blend-mode: normal;
}

/* --About me Section */

/* Project Section */

.project_title {
  padding-left: 15px;
  margin-bottom: 30px;
  font-size: 40px;
  color: #ccd6f6;
}

.main.main-project {
  margin-bottom: 80px;
}

.main.main-project .img-container {
  background-color: #64ffda;
  margin-bottom: 20px;
  display: block;
}

.main.main-project .img-container img {
  max-width: 100%;
  height: auto;
  filter: grayscale(100%);
  mix-blend-mode: multiply;
  transition: opacity 500ms ease 0s;
}

.main.main-project .img-container:hover img {
  filter: none;
  mix-blend-mode: normal;
}

.main.main-project .left-container span {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

.main.main-project .left-container .text-content {
  background-color: #172a45;
  padding: 10px;
  margin: 15px 0px;
  border-radius: 15px;
}

.main.main-project .left-container .technologies li {
  display: inline-block;
  padding-right: 10px;
  font-size: 14px;
  margin-bottom: 20px;
}

.main.main-project .left-container h4 {
  color: #e6f1ff;
  font-size: 24px;
}

.main.main-project .left-container .links-feature a {
  color: #e6f1ff;
  padding-right: 10px;
  margin-bottom: 10px;
}

/* --Project Section */

/* Get In Touch Section */
.main.get_in_touch .title {
  font-size: 40px;
  color: #ccd6f6;
  margin-bottom: 30px;
  text-align: center;
}

.main.get_in_touch p {
  text-align: center;
  margin-bottom: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.main.get_in_touch a {
  margin-bottom: 45px;
  background-color: transparent;
  border: 1px solid #64ffda;
  padding: 20px 25px;
  border-radius: 5px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 15px;
  color: #64ffda;
  text-decoration: none;
  margin: 20px auto;
  text-align: center;
  display: block;
  width: 100px;
}
/* --Get In Touch  Section */

/* Footer Section */
footer {
  margin: 40px 0;
}

footer .socialMedia {
  text-align: center;
}

footer .socialMedia ul li a {
  padding-right: 15px;
  color: #e6f1ff;
}

/* --Footer  Section */
